<template lang="pug">
  div
    CCard
      CCardBody
        CRow
          CCol(sm=5)
            h4(class="card-title mb-0") Продавцы

        vuecrud(host="http://wild", selectedtable = "sellers", :columnsupdated="columnsupdated", :opts="opts", style="margin-top:15px")
          template(v-slot:row="binds")
            tr
              td
                div
                  img(:src="'/img/services/' + binds.row.service + '.ico'")
                  span &nbsp; {{binds.row.name}}
                div {{binds.row.path}}
              td
                div {{format(binds.row._goods)}}
              td
                div {{format(binds.row._sales)}}
              td
                div {{format(binds.row._oborot)}}
              td
                div {{format(binds.row._avgPrice)}}
              td
                div {{format(binds.row._brands)}}


</template>

<script>

export default {
  name: 'Sellers',
  data: function () {
    return {
      show: true,
      isCollapsed: true,

      opts: {
        canAdd: false,
        canEdit: false,
        canDelete: false
      }

    }
  },
  methods: {
    columnsupdated: function (cols) {

      cols.set('name', {label: "Название", hasFilter: true, });
      cols.delete('id');
      cols.delete('service');
      cols.delete('sourceID');
      cols.delete('ogrn_russia', {label: "ОГРН", hasFilter: true, });
      cols.delete('inn', {label: "ИНН любой страны", hasFilter: true, });
      cols.delete('address', {label: "адрес, если выдаётся", hasFilter: true, });

      cols.set('_sales', {label: "Продаж", hasFilter: true, });
      cols.delete('_sellers', {label: "Продавцов", hasFilter: false, });
      cols.set('_brands', {label: "Брендов", hasFilter: true, });
      cols.set('_oborot', {label: "Выручка", hasFilter: true, });
      cols.set('_goods', {label: "Товаров", hasFilter: true, });
      cols.set('_avgPrice', {label: "Ср. чек", hasFilter: true, });

    },

    gotoItem(row) {
      this.$router.push({name: 'Seller', params:{id: row.sourceid}});
    },


    format(number) {
      return (new Intl.NumberFormat('ru-RU').format(Math.round(number))).toString();
    }

  }
}
</script>


<style scoped>
.value {
  color: blue;
  cursor: pointer;
}
.value:hover {
  text-decoration: underline;
}
</style>